<template>
  <div></div>
</template>

<script>
export default {
  name: "Redirect",
  mounted() {
    window.location.href = `https://${this.$route.meta.location}`;
  }
}
</script>